.files {
  padding: .5rem .5rem 7.5rem;
}

.files-header {
  display: flex;
  align-items: center;
  &__icon {
    margin-right: .5rem;
    path {
      fill: currentColor;
    }
  }

  &::after {
    margin-inline-start: 1rem;
    content: "";
    border-bottom: 2px solid currentColor;
    opacity: .5;
    flex: 1;
  }
}

.file-list {
  border-block: 1px solid rgba(112, 112, 112, 0.15);
}

.main-list {
  color: #737373;

  li:nth-child(1) {
    color: #737373;
  }

  li span {
    color: red;
  }
  li {
    padding-left: 12px;
  }
  }