@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
$brief-text-color: #b3b3b3;
@import "./bulma.scss";
@import "./scss/Variables";

html,
body {
  font-family: "Roboto";
  font-weight: 400;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.375rem;
  }

  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.075);
    border-radius: 0.1875rem;
    background-color: $scrollbar-track-color;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.1875rem;
    background-color: $scrollbar-color;

    &:hover {
      background-color: $scrollbar-hover-color;
    }
  }
}

.navbar-togglers {
  display: flex;
  align-items: center;
  justify-content: center;

  .bigger {
    font-size: 24px !important;
    font-weight: 700;
  }
}

.progress-bar {
  position: relative;
  height: 0;

  &__progress {
    height: 0.25rem;
    background-color: $orange;
  }
}

.pointer {
  cursor: pointer;
}

.wrap {
  flex-wrap: wrap;
}

.items-spaced {
  display: flex;
  justify-content: space-between;
}

.items-right {
  display: flex;
  justify-content: flex-end;
}

.items-right {
  .sample {
    width: auto;
    height: inherit;
  }
}

.column.has-items-right,
.columns.has-items-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.column.has-items-centered,
.columns.has-items-centered {
  display: flex;
  align-items: center;
}

.columns.is-spaced {
  justify-content: space-between;
}

.not-transparent-on-hover {
  &:hover {
    opacity: 1 !important;
  }
}

.scale-on-hover {
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}

.error {
  color: red;
  font-size: 0.75rem;
}

.moidal-content {
  flex: 1;
}

.field-section > .rows > .row,
.modal-content.rows > .row {
  padding: 0.5rem !important;
}

.field-section--inner {
  margin: 0 20px;
  background-color: #fff;
}

.rows {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
}

.row {
  display: flex;
  margin: 0 !important;
}

.rows .row {
  flex: 1;
  position: relative;
}

.row.rows {
  padding: 0;
}

.row > .column {
  padding: 0;
}

.text-center {
  text-align: center;
}

.default-height {
  height: 100% !important;
}

.horizontal-line {
  flex: 1;
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 0 0.5rem;
  border-bottom: 1px solid #f5f5f5;
}

.horizontal-line--light-blue {
  border-bottom: 1px solid rgba(129,211,209,1) !important;
}

.bolder {
  font-weight: 500;
}

.caret {
  cursor: pointer;
  height: $caret-icon-size;
  width: $caret-icon-size;
  transition: all 0.3s ease-out;

  &--closed {
    transform: translate(-50%, -50%);
    transform: rotate(0deg);
  }

  &--opened {
    transform: translate(-50%, -50%);
    transform: rotate(90deg);
  }

  &--white {
    fill: #fff;
  }
}

.project {
  padding: 0.25rem 0.5rem;
  &__product-count {
    font-size: 0.5625rem;
    border-radius: 0.25rem;
    background-color: #cccccc;
    color: #fff;
    padding: 0.0625rem 0.25rem;
    margin-right: 0.375rem;
  }
  &__slug {
    text-transform: uppercase;
    font-size: $font-title;
    margin: 0.5rem 0 0 0;
    color: $dark-grey;

    &--active {
      color: $link-active-color !important;
      font-weight: 700;
    }

    &:hover {
      color: $link-hover-color;
      cursor: pointer;
    }
  }

  &__name {
    margin-left: 0.5rem;
    font-size: 0.8125rem;
  }
}

.no-border {
  border: none !important;
  box-shadow: none !important;
}

.step-progress {
  padding: 2rem 2rem 1rem 2rem;
}

.steps.step-progress .steps-marker.is-white.tooltip {
  color: #fff !important;
}

.navbar-item > .avatar {
  height: 2.25rem !important;
  width: 2.25rem !important;

  img {
    height: inherit;
    width: inherit;
    border-radius: 50%;
    max-height: 2.25rem !important;
  }
}

.stage-bar {
  display: flex;
  flex-direction: column;

  &__box {
    display: flex;
    height: $stage-bar-box-size;
    width: $stage-bar-box-size;
    border-width: 1px 2px 1px 0px;
    border-style: solid;
    border-color: $stage-bar-box-border-color;
    justify-content: center;
    align-items: center;

    &--active {
      border-right: none;
      border-left: 3px solid #f3d124;
      cursor: pointer;
    }

    &--active-icon {
      border-right: none;
      border-left: 3px solid #949494;
    }

    &:first-child {
      border-top: none;
    }

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: $stage-bar-item-size;
      width: $stage-bar-item-size;
      padding: 7px;
      border: 3px solid $stage-bar-item-border-color;
      border-radius: 100%;
      color: #666666;
      font-size: 0.5625rem;

      svg {
        height: 20px;
        width: 20px;
      }

      &--disabled {
        opacity: 0.5;
        background-color: $stage-bar-item-border-color;
      }

      &--active {
        border: none;
        background-color: #f58c39;
        border: 3px solid $stage-bar-item-border-color-active;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

.brief-container {
  box-shadow: $container-box-shadow;
  background-color: #FAFAFA;
  min-height: $container-height;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.retry-container {
  @extend .brief-container;
  display: flex;
  justify-content: center;
  align-items: center;
}

.retry-dashboard {
  @extend .brief-container;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;

  &__id {
    margin-left: 0.5rem;
    color: $light-grey;
    &::first-line{
      line-height: 1.25rem;
    }

    &:hover {
      color: $link-hover-color;
      cursor: pointer;
    }

    &--active {
      color: $link-active-color;
    }
  }
}

.relative {
  position: relative;
}

.login {
  &__container {
    padding: 2rem;
    background-color: $bg-light;
    border-radius: $container-border-radius;
    box-shadow: $container-box-shadow;
  }

  &__pos {
    justify-content: center;
  }

  &__background {
    height: 100vh;

    img {
      height: 100vh;
      width: 100%;
      object-fit: fill;
    }
  }

  &__logo {
    height: 3.25rem;
  }

  &__icon {
    position: absolute;
    left: 0.5rem;
    top: 1rem;
  }
}

.stage-stamp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 20px;
  border-radius: 0.3125rem;
  font-size: 0.7rem;

  &__label {
    color: #fff;
  }

  &__light-bg {
    background-color: $light-grey;
  }
}

.container-header {
  background-color: $grey;
  border-radius: $header-border-radius;
}

.left-sidebar {
  z-index: 1;
}

.left-header {
  @extend .container-header;
  & div > div > button:nth-of-type(1) {
    margin-left: 1rem !important;
  }
  &__title {
    margin-left: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
  }
}

.order-button {
  &:nth-child(2) {
    margin-left: 2rem;
  }
  padding: 0.375rem 0.5rem !important;
  font-size: 0.5rem !important;
  margin: 0 0.125rem !important;
  &__label {
    color: #fff;
    font-size: 0.5rem !important;
    line-height: 0;
  }
}

.right-header {
  @extend .container-header;
  padding: 1rem;

  &__slug {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1rem;
    color: #fff;
  }

  &__model {
    margin-left: 0.55rem;
    font-size: 0.9125rem;
    color: $very-light-grey;
  }
}

.file-list-header,
.project-members-header {
  @extend .container-header;
  padding: 1rem;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: $container-box-shadow;

  &__title {
    margin-left: 0.875rem;
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
  }
}

.file-list-header {
  &__title {
    display: flex;
    align-items: center;
  }

  &__link-icon {
    display: flex;
    background: none;
    padding: 0;
    border: 0;
    cursor: pointer;

    svg {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
}

.project-members-footer {
  box-shadow: $container-box-shadow;

  border-radius: $container-border-radius;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: $bg-light;

  height: 5vh;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@mixin right-sidebar-element-content {
  box-shadow: $container-box-shadow;

  min-height: $project-members-content;
  max-height: $project-members-content + 5;
  background-color: $bg-light;
  overflow: auto;

  &__closed {
    display: none;
  }
}

.file-list-content {
  @include right-sidebar-element-content;
  padding-bottom: 5rem;
}
.project-members-content {
  @include right-sidebar-element-content;
}

.project-members-add-new {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
}

.project-member-item {
  border-bottom: 1px solid #f5f5f5;
  padding: 0.5rem;

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      border-radius: 50%;
      height: 36px;
      width: 36px;
      // object-fit: contain;
    }
  }

  &__full-name {
    font-size: 0.8125rem;
    font-weight: 500;
    color: #707070;
  }

  &__phoneNumber {
    font-size: 0.8125rem;
    color: #b3b3b3;
  }

  &__email {
    font-size: 0.75rem;
    font-weight: 500;
    color: #b3b3b3;
  }

  &__jobTitle {
    font-size: 0.6875rem;
    font-weight: 500;
    color: #b3b3b3;
  }
}

.search-bar {
  margin-bottom: 0.25rem;

  &__input {
    box-shadow: $container-box-shadow;
    border-radius: $container-border-radius;
    font-size: 1rem !important;
    padding: 1.5rem !important;
  }
}

.full-height-column {
  // border-radius: 0 0 $container-bottom-border-radius $container-bottom-border-radius;
  min-height: auto;
  max-height: vh;
  background-color: $bg-light;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
}

.selected-project-container {
  background-color: #ededed;
  display: flex;
  flex-direction: column;
}

.activity-log {
  box-shadow: $container-box-shadow;
  border-radius: $container-border-radius;

  &__content {
    min-height: $activity-log-height;
    max-height: $activity-log-height + 5;
    overflow: auto;
    background-color: $bg-light;
  }

  &__footer {
    background-color: $bg-light;
    border-top: 1.5px solid rgba($medium-grey, 0.15);
  }

  &__author {
    font-size: 0.9375rem;
    font-weight: 500;
    color: $medium-grey;
  }

  &__date {
    font-size: 0.75rem;
  }

  &__description {
    margin-left: 0.25rem;
    font-size: 0.8125rem;
  }
}

.image-picker,
.picker,
.image {
  display: flex;
  align-items: center;
  justify-content: center;

  &__with-remove {
    position: relative;

    svg {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      opacity: 0.5;
    }
  }

  &__box {
    position: relative;
    border: $thick-border solid #f5f5f5;
    background-color: #ffffff;
    margin: 0.125rem;
    width: 160px;
    height: 100px;

    img {
      width: 160px - $thick-border * 2;
      height: 100px - $thick-border * 2;
      object-fit: cover;
    }

    &--big {
      width: 200px;
      height: 200px;

      img {
        width: 200px - $thick-border * 2;
        height: 200px - $thick-border * 2;
        object-fit: cover;
      }
    }

    &--small {
      width: 90px;
      height: 90px;

      img {
        width: 90px;
        height: 90px;
        object-fit: cover;
      }
    }

    &--big-hoverable {
      color: white;
      position: absolute;
      top: 0;
      opacity: 0;
      width: 200px - $thick-border * 2;
      height: 200px - $thick-border * 2;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  &__input {
    display: none;
  }
}

.create-message {
  padding: 0.5rem 0.25rem;
}

.brief {
  width: 100%;
  display: flex;
  border-top: 2px solid #f2f2f2;

  &__title {
    font-weight: 500;
    color: $dark-grey;
    text-transform: uppercase;
    margin-right: 80px;
  }

  .display-sample {
    color: $dark-grey;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__container {
    min-width: 525px;
    flex-grow: 1;
  }

  &__section-title {
    color: $light-grey;
  }

  &__input {
    padding: 0.625rem;
    height: auto;

    &--select {
      width: 5rem !important;
      height: $dropdown-height !important;

      select {
        height: inherit !important;
        width: inherit !important;
      }
    }
  }

  &__form {
    flex-grow: 1;
  }
}

.photo-file {
  position: relative;

  &__title {
    font-weight: 500;
    font-size: 1.0625rem;
    color: $medium-grey;
  }

  &__description {
    font-weight: 400;
    font-size: 0.9375rem;
    overflow-wrap: break-word;
  }

  img {
    height: inherit;
    width: inherit;
  }

  &--hovered {
    color: white;
    position: absolute;
    top: 0;
    opacity: 0;
    width: inherit;
    height: inherit;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      opacity: 1;
    }
  }
}

.file {
  background-color: $file-bg;
  border: $thick-border solid $file-border;
  padding: 0.5rem;

  &__name-background {
    height: 3rem;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($orange, 0.4);
    color: $medium-grey;
  }

  &:first-child {
    margin-top: 0.5rem;
  }

  &__size {
    color: #bcbcbc;
    font-size: 0.75rem !important;
  }
}

.font-small {
  font-size: 0.75rem;
}

.font-dark {
  color: #666;
}

.is-dimmed {
  color: $brief-text-color !important;

  svg {
    fill: $brief-text-color;
  }
}

.file-list-item {
  padding: 0.65rem;

  span {
    font-size: 0.75rem;
  }

  &__master-draft {
    &--iconAccepted {
      fill: $stage-2 !important;
    }

    &--nameAccepted {
      color: $stage-2 !important;
    }
  }

  &__draft {
    &--iconAccepted {
      fill: $stage-3 !important;
    }

    &--nameAccepted {
      color: $stage-3 !important;
    }
  }

  &__ff {
    &--iconAccepted {
      fill: $stage-4 !important;
    }

    &--nameAccepted {
      color: $stage-4 !important;
    }
  }

  &__pf {
    &--iconAccepted {
      fill: $stage-5 !important;
    }

    &--nameAccepted {
      color: $stage-5 !important;
    }
  }

  &:first-child {
    margin-top: 0.5rem;
  }

  &:last-child {
    border-bottom: 1px solid $file-border;
  }

  border-top: 1px solid $file-border;

  &--accepted {
    color: #f5613c;
  }

  &__dialog {
    border: $thick-border solid $file-border;
  }

  &__modal-filename {
    padding: 0.75rem;
    height: 120px;
    width: 100%;
    background-color: rgba($orange, 0.3);

    span {
      font-size: 0.75rem;
      font-weight: 500;
    }
  }

  &__modal-description {
    margin: 2rem 0 1rem 0;
  }
}

.remove-right-radius {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.button-checkbox {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 12px;

  input {
    display: none;
  }

  margin: 0.5rem 0.5rem 0 0;
  color: $light-grey !important;

  &--is-active-blue {
    background-color: rgba(129,211,209) !important;
    color: white !important;

    svg {
      fill: white !important;
    }
  }

  &--is-active {
    background-color: $orange !important;
    color: white !important;

    svg {
      fill: white !important;
    }
  }

  &--is-disabled {
    cursor: initial;
  }

  &__tooltip {
    svg {
      fill: $light-grey;
    }
  }

  &__delete {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}

.form-checkbox {
  display: none;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

   label + & + label {
    margin-left: 1rem; 
  }

  & + label:before {
    content: "";
    margin-right: 0.375rem;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    border-radius: 0.25rem;
    background: $checkbox-bg-color;
  }

  &[disabled] + label {
    cursor: default;
  }

  &:hover:not([disabled]) + label:before {
    background: $checkbox-hover-color;
  }

  &:checked + label:before {
    background: $checkbox-checked-color;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}

.datepicker {
  position: relative;

  input {
    font-size: 0.75rem;
    height: $dropdown-height;
    padding-right: $dropdown-height - 1rem;
  }

  svg {
    position: absolute;
    top: 3 * $dropdown-height/10;
    right: 3 * $dropdown-height/10;
    width: $dropdown-height/2.5;
    height: $dropdown-height/2.5;
  }

  cursor: pointer;

  span {
    margin-right: 0.5rem;
  }
}

.modal-content {
  overflow: auto;
  background-color: $bg-light;
  overflow: hidden;
  padding: 0.5rem;

  &__header {
    font-size: 1.25rem;
    color: $dark-grey;
  }

  &__image {
    img {
      max-height: 60vh;
      object-fit: contain;
    }
  }
}

.with-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--left {
    svg {
      margin: 0 1rem 0 0.15rem;
    }
  }

  &--right {
    svg {
      margin: 0 0.15rem 0 1rem;
    }
  }
}

@media screen and (max-width: 1430px){
  .stacked {
    display: flex;
    flex-direction: column;
  }
}

$values: 10;

@mixin custom-margins {
  @for $i from 0 through $values {
    .mt-stacked-#{$i}{
      @media screen and (max-width: 1430px){
        margin-top: $i * 8px !important;
      }
    }
    .ml-stacked-#{$i}{
      @media screen and (max-width: 1430px){
        margin-left: $i * 8px !important;
      }
    }
    .mr-stacked-#{$i}{
      @media screen and (max-width: 1430px){
        margin-right: $i * 8px !important;
      }
    }
    .mb-stacked-#{$i}{
      @media screen and (max-width: 1430px){
        margin-bottom: $i * 8px !important;
      }
    }
    .m-#{$i} {
      margin: $i * 8px;
    }

    .mt-#{$i} {
      margin-top: $i * 8px;
    }

    .mb-#{$i} {
      margin-bottom: $i * 8px;
    }

    .ml-#{$i} {
      margin-left: $i * 8px !important;
    }

    .mr-#{$i} {
      margin-right: $i * 8px;
    }
  }
}

@mixin custom-paddings {
  @for $i from 0 through $values {
    .p-#{$i} {
      padding: $i * 8px !important;
    }

    .pt-#{$i} {
      padding-top: $i * 8px !important;
    }

    .pb-#{$i} {
      padding-bottom: $i * 8px !important;
    }

    .pl-#{$i} {
      padding-left: $i * 8px !important;
    }

    .pr-#{$i} {
      padding-right: $i * 8px !important;
    }
  }
}

@mixin custom-gaps {
  @for $i from 0 through $values {
    .gap-#{$i} {
      gap: $i * 8px !important;
    }
  }
}

@include custom-paddings;
@include custom-margins;
@include custom-gaps;

#dark {
  background-color: #333;

  *::placeholder {
    color: #fff;
  }

  .navbar-menu {
    background-color: #333;
  }

  .navbar-item {
    background-color: #333;

    .navbar-dropdown {
      background: border-box;
      border: 1px solid #fff;
    }
  }

  .navbar-togglers {
    background-color: #333;
  }

  ul.dropdownmenu-items {
    border: 2px solid #fff;
    padding: 0;

    li.row.dropdownmenu-items__item.bolder {
      background: #222;
    }
  }

  .search-bar__input {
    color: #fff;
    border-color: #fff;
    background: border-box;

    &::placeholder {
      color: #fff;
    }
  }

  .selected-project-container {
    background: border-box;

    .rows {
      border: 1px solid #fff;
    }

    .row.rows {
      border: none;
    }

    .project__product-count {
      color: #000;
      font-weight: 700;
    }
  }

  .full-height-column {
    background: border-box;

    .rows {
      border: 1px solid #fff;

      * {
        color: #fff;

        .project__slug {
          &:hover {
            color: orange;
          }
        }
      }

      .project__product-count {
        color: #000;
        font-weight: 700;
      }
    }
  }

  .left-header {
    background: border-box;
    border: 1px solid #fff;
  }

  .column {
    background: #333;
    
    .brief-container {
      background: border-box;
      border: 1px solid #fff;

      .is-input-border{
        background: border-box;
        color: #fff
      }

      select.is-input-border.brief__input--select {
        background: border-box;
      }

      button.is-outlined.is-fullwidth {
        background: border-box;
        border: 1px solid #fff;

        span {
          color: #fff;

          .icon.is-small {
            color: #fff
          }
        }
      }

      .image-picker__box.pointer {
        background: border-box;
        border: 1px solid #fff;
      }

      .button.is-light-grey.button-checkbox {
        background: border-box;
        border: 1px solid #fff;
      }

      .stage-bar__box__item {
        background: #fff;

        &--active {
          background: orange;
        }
      }

      .brief__form {
        span {
          color: #fff;
        }
      }
    }

    .file {
      background-color: transparent;
    }
  }

  .field-section--inner {
    background: border-box;
  }

  .right-container {

    .rows.right-header {
      background: border-box;
      border: 1px solid #fff;
    }

    .activity-log__content {
      background: border-box;
      border: 1px solid #fff;
    }

    .file-list-header,
    .file-list-content,
    .project-members-header,
    .project-members-content {
      background: border-box;
      border: 1px solid #fff;
    }

    .file-list-content {
      background: border-box;
      border: 1px solid #fff;
    }

    input {
      background: border-box;
      border: 1px solid #fff !important;
      &::placeholder {
        color: #fff; 
      }
    }

    .is-marginless {
      background: #333;
    }
  }
}

.nested {
  position: relative;

  ::before {
    content: '';
    position: absolute;
    border: 1px solid #b3b3b3;
    height: 10px;
    width: 1px;
    top: -3px;
    left: -12px;
  }

  ::after {
    content: '';
    position: absolute;
    border: 1px solid #b3b3b3;
    height: 1px;
    width: 8px;
    top: 7px;
    left: -12px;
  }
}