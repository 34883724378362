.dropdownmenu {
    position: relative;
    z-index:1;
    justify-content: flex-end;
    
    .dropdownmenu-items {
        position: absolute;
        bottom: -164px;
        right: -251px;
        padding: 0.5rem 0;
        color: #b3b3b3;
        border-radius: 0.25rem;
        background-color: #fff;
        filter: drop-shadow(0px 5px 30px #00000026);

        &::before {
            $triangle-size: 5px;
            $triangle-height: $triangle-size * 2;

            content: '';
            position: absolute;
            top: -$triangle-height;
            left: 9px;
            width: 0;
            height: 0;
            border-left: $triangle-size+5px solid transparent;
            border-right: $triangle-size+5px solid transparent;
            border-bottom: $triangle-size*2 solid #fff;
        }

        &__item {
            position: relative;
            padding: 1rem 1.5rem;
            background-color: #fff;
            border-radius: 0.25rem;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 1.5rem;
            flex-wrap: nowrap;
            white-space: nowrap;

            &--disabled {
                cursor: not-allowed;
                background-color: #f1f1f1;
            }

            &:hover {
                background-color: #f58c3980;
                color: #fff;
            }

            &:hover svg {
                fill: #fff;
            }

            svg {
                fill: #d0d1cd;
            }
        }
    }
}