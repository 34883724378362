.addCircleButton {
    display: flex;
    align-items: flex-end;
    height: 52px;

    &__icon {
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        margin-right: 1rem;

        .icon--blue path {
            fill: rgba(129,211,209,1);
        }
    }

    &__field {
        position: relative;
        filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.149));

        &::before {
            $triangle-size: 5px;
            $triangle-height: $triangle-size * 2;

            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -$triangle-height;
            width: 0;
            height: 0;
            border-top: $triangle-size+5px solid transparent;
            border-bottom: $triangle-size+5px solid transparent;
            border-right: $triangle-size*2 solid #fff;
        }
    }

    .field__input {
        background-color: #fff;
        z-index: 1;
        padding: .6rem .5rem;
        text-transform: uppercase;
        border: 2px solid #eff0eb;
        font-size: .7rem;
        border-radius: 5px;
        text-align: center;

        &--error {
            border-color: red;
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.125em rgb(219 219 219 / 25%);
        }
    }
}