.file-list-entry {
  padding-block: 1rem;
  border-block: 1px solid rgba(112, 112, 112, 0.15);

  &__container {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    margin-inline: 1rem;

    &--summary {
      margin-inline: .5rem;
      gap: .5rem;
      font-size: .85em;
    }
  }

  &__download-icon {
    padding: 0 1rem;
  }

  &__delete,
  &__expand {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    cursor: pointer;
  }

  &__expand {
    width: 2rem;
    padding: .75rem;
    svg {
      scale: 1.5;
    }
  }

  &__delete {
    svg {
      scale: 1.5;

      // Hack for making the X white
       g > g > g:nth-child(2) > g > path {
         fill: white;
       }
    }
  }

  &__file-brief {
    display: flex;
    flex: 1;

    color: rgba(137, 137, 137, 1);
    svg {
      flex-shrink: 0;

      padding: .75rem;
      height: 60px;
    }
  }

  &__file-name {
    color: rgba(102, 102, 102, 1);
  }

  &__file-size {
    color: rgba(188, 188, 188, 1);
  }

  &__summary-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__upload-summary {
    display: flex;
    gap: .75rem;
  }
}

.file-list-entry-description {
  background: #fafafa;
  border: 1px solid rgba(112, 112, 112, 0.15);
  padding: 1rem;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &--summary {
    font-size: .85em;
    margin-bottom: -1rem;
    border-bottom: 0;
  }

  .button {
    align-self: flex-end;
  }
}

.file-list__button {
  display: flex;
  gap: 1rem;
  padding-block: 1.25rem;

  svg path {
    fill: currentColor;
  }
}

.files-actions {
  margin-block: 1rem;
  display: flex;
  justify-content: flex-end;
}
