$font-title: 0.9125rem;
$orange: #f58c39;

$dark-grey: #737373;
$grey: #b3b3b3;
$medium-grey: #707070;
$light-grey: #b3b3b3;
$very-light-grey: #eaeaea;
$bg-light: #fff;
$file-border: #f2f2f2;
$file-bg: #fbfbfb;
$thick-border: 2px;

$link-active-color: $orange;
$link-hover-color: rgba($link-active-color, 0.5);
$scrollbar-color: #cccccc;
// $scrollbar-track-color: lighten($scrollbar-color, 15%);
$scrollbar-track-color: #fff;
$scrollbar-hover-color: $orange;
$container-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
$container-border-radius: 0.375rem;
$container-bottom-border-radius: 0.375rem;
$header-border-radius: 0.375rem 0.375rem 0 0;
$searchbar-height: 5vh;

$activity-log-height: 55vh;
$dropdown-height: 3rem;
$container-height: 80vh;
$checkbox-bg-color: #999999;
$checkbox-hover-color: rgba($orange, 0.5);
$checkbox-checked-color: rgba($orange, 1);

$stage-bar-box-size: 60px;
$stage-bar-item-size: 35px;

$stage-1: #f3d124;
$stage-2: #99C12E;
$stage-3: #f5613c;
$stage-4: #a72c88;
$stage-5: #73278f;
$stage-6: #4d4c4d;

$stage-bar-box-border-color: $file-border;
$stage-bar-item-border-color: #919191;
$stage-bar-item-border-color-active: $stage-1;

$project-members-content: 25vh;

$caret-icon-size: 10px;
