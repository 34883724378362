.custom-tooltip {
    position: relative;
    cursor: initial;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &__text {
        position: absolute;
        bottom: -350%;
        left: -7px;
        padding: 0.5rem 1rem;
        color: #b3b3b3;
        border-radius: 0.25rem;
        background-color: #fff;
        filter: drop-shadow(0px 5px 30px #00000026);
        z-index: 1;

        &::before {
            $triangle-size: 5px;
            $triangle-height: $triangle-size * 2;

            content: '';
            position: absolute;
            top: -$triangle-height;
            left: 5px;
            width: 0;
            height: 0;
            border-left: $triangle-size+5px solid transparent;
            border-right: $triangle-size+5px solid transparent;
            border-bottom: $triangle-size*2 solid #fff;
        }
    }
}